<template>
  <div class="networkDashboard js-tabBox networkDashboard--admin">
    <div class="networkDashboard__header">
      <div class="networkDashboard__title">Network sign-ups</div>
      <div class="networkDashboard__nav">
        <div class="networkDashboard__nav-item js-tabNavItem" @click="tabActivate">
          <span class="text">Flyers</span>
        </div>
        <div data-id="aircraft-providers" class="networkDashboard__nav-item js-tabNavItem" @click="tabActivate">
          <span class="text">Aircraft Providers</span>
        </div>
        <button>
          <span
          @click="exportToExcel()"
          class="text">Export</span>
        </button>
      </div>
      <div class="networkDashboard__button-wr"
        :class="{'active' : createShow}"
        data-id="aircraft-providers"
      >
        <span class="networkDashboard__button" @click="$modal.show('SignUpProviderPopup')">
          <span class="text">Create</span>
        </span>
      </div>
    </div>
    <div class="networkDashboard__content">
      <div class="networkDashboard__content-item js-tabContentItem">
          <div class="networkDashboard__table">
            <Pagination
            :pageCount="flyersListPageCount"
            :rowCount="flyersListCount"
            @pageClick="onFlyersPageClick"
          />
          <div class="networkDashboard__table-header">
            <div class="networkDashboard__table-cell">№</div>
            <div class="networkDashboard__table-cell">Name</div>
            <div class="networkDashboard__table-cell">Email</div>
            <div class="networkDashboard__table-cell">Phone</div>
            <div class="networkDashboard__table-cell">Date/time</div>
          </div>
          <div class="networkDashboard__table-body js-customScroll">
            <vue-scroll :ops="ops">
              <div
                v-for="item of flyersList"
                :key="item.id"
                class="networkDashboard__table-row js-acc-item"
              >
                <div class="networkDashboard__table-row-header js-acc-title" @click="togglePayment">
                  <div class="networkDashboard__table-cell">
                    <div class="networkDashboard__table-index">{{item.number}}</div>
                  </div>
                  <div class="networkDashboard__table-cell">
                    <a
                    @click="openAdditionalDetails(item)"
                    class="networkDashboard__table-name">{{item.firstName + ' ' + item.lastName}}</a>
                  </div>
                  <div class="networkDashboard__table-cell">{{item.email}}</div>
                  <div class="networkDashboard__table-cell">
                    <div class="networkDashboard__table-phone">{{item.mobile}}</div>
                  </div>
                  <div class="networkDashboard__table-cell">
                    <span>{{getDisplayDateTime(item.createdOn)}}</span>
                    <div class="networkDashboard__table-arrow">
                      <SvgIcon name="down-arrow"/>
                    </div>
                  </div>
                </div>
                <!-- <div class="networkDashboard__table-row-content js-acc-content-network">
                  <div class="networkDashboard__table-row-content-inner">
                    <div class="networkDashboard__history">
                      <div class="networkDashboard__history-title"><b>History of visits</b> for Mical Horg</div>
                      <div class="networkDashboard__history-list">
                        <div
                          v-for="(elem, index) of item.history"
                          :key="index"
                          class="networkDashboard__history-item"
                        >
                          <div class="networkDashboard__history-info">
                            <div v-if="elem.icon" class="networkDashboard__history-icon">
                              <SvgIcon :name="elem.icon"/>
                            </div>
                            <div class="networkDashboard__history-inner">
                              <div class="networkDashboard__history-session">{{elem.title}}</div>
                              <div v-if="elem.online" class="networkDashboard__history-status">online</div>
                              <div class="networkDashboard__history-date">
                                today at <b>{{elem.time}}</b>, Browser: {{elem.browser}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="networkDashboard__history-item">
                          <div class="networkDashboard__history-info">
                            <div class="networkDashboard__history-icon">
                              <SvgIcon :name="'notebook'"/>
                            </div>
                            <div class="networkDashboard__history-inner">
                              <div class="networkDashboard__history-session">Windows, New York, USA</div>
                              <div class="networkDashboard__history-status">online</div>
                              <div class="networkDashboard__history-date">
                                today at <b>2:00 PM</b>, Browser: Chrome
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </vue-scroll>
          </div>
        </div>
      </div>
      <div class="networkDashboard__content-item js-tabContentItem">
        <div class="networkDashboard__table">
            <Pagination
            :pageCount="providersListPageCount"
            :rowCount="providersListCount"
            @pageClick="onProvidersPageClick"
            />
          <div class="networkDashboard__table-header">
            <div class="networkDashboard__table-cell">№</div>
            <div class="networkDashboard__table-cell">Name</div>
            <div class="networkDashboard__table-cell">Email</div>
            <div class="networkDashboard__table-cell">Phone</div>
            <div class="networkDashboard__table-cell">Date/time</div>
          </div>
          <div class="networkDashboard__table-body js-customScroll">
            <vue-scroll :ops="ops">
              <div
                v-for="item of providersList"
                :key="item.id"
                class="networkDashboard__table-row js-acc-item"
              >
                <div class="networkDashboard__table-row-header js-acc-title" @click="togglePayment">
                  <div class="networkDashboard__table-cell">
                    <div class="networkDashboard__table-index">{{item.number}}</div>
                  </div>
                  <div class="networkDashboard__table-cell">
                    <a
                    @click="openAdditionalDetails(item)"
                    class="networkDashboard__table-name">{{item.firstName + ' ' + item.lastName}}</a>
                  </div>
                  <div class="networkDashboard__table-cell">{{item.email}}</div>
                  <div class="networkDashboard__table-cell">
                    <div class="networkDashboard__table-phone">{{item.mobile}}</div>
                  </div>
                  <div class="networkDashboard__table-cell">{{getDisplayDateTime(item.createdOn)}}
                    <div class="networkDashboard__table-arrow">
                      <SvgIcon name="down-arrow"/>
                    </div>
                  </div>
                </div>
                <!-- <div class="networkDashboard__table-row-content js-acc-content-network">
                  <div class="networkDashboard__table-row-content-inner">
                    <div class="networkDashboard__history">
                      <div class="networkDashboard__history-title"><b>History of visits</b> for {{item.name}}</div>
                      <div class="networkDashboard__history-list">
                        <div
                          v-for="(elem, index) of item.history"
                          :key="index"
                          class="networkDashboard__history-item"
                        >
                          <div class="networkDashboard__history-info">
                            <div v-if="elem.icon" class="networkDashboard__history-icon">
                              <SvgIcon :name="elem.icon"/>
                            </div>
                            <div class="networkDashboard__history-inner">
                              <div class="networkDashboard__history-session">{{elem.title}}</div>
                              <div v-if="elem.online" class="networkDashboard__history-status">online</div>
                              <div class="networkDashboard__history-date">
                                today at <b>{{elem.time}}</b>, Browser: {{elem.browser}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="networkDashboard__history-item">
                          <div class="networkDashboard__history-info">
                            <div class="networkDashboard__history-icon">
                              <SvgIcon :name="'notebook'"/>
                            </div>
                            <div class="networkDashboard__history-inner">
                              <div class="networkDashboard__history-session">Windows, New York, USA</div>
                              <div class="networkDashboard__history-status">online</div>
                              <div class="networkDashboard__history-date">
                                today at <b>2:00 PM</b>, Browser: Chrome
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </vue-scroll>
          </div>
        </div>
      </div>
    </div>
    <SignUpProviderPopup/>
    <FlyerDetailsPopup />
  </div>
</template>

<script>
  import { reportsAPI } from '@/api/reports'
  import { Tabs } from '@/js/plugins/tabs'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import SignUpProviderPopup from '@/components/common/modals/SignUpProviderPopup'
  import FlyerDetailsPopup from '@/components/common/modals/FlyerDetailsPopup'
  import Pagination from '@/components/common/Pagination/Pagination'
  import './Network.styl'
  import moment from 'moment'

  export default {
    name: 'Network',
    data: () => ({
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      createShow: false,
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      tabSelected: 'flyers',
    }),
    computed: {
      flyersList () {
        return this.$store.state.network.flyersList
      },
      providersList () {
        return this.$store.state.network.providersList
      },
      flyersListPageCount () {
        return this.$store.state.network.flyersListPageCount
      },
      providersListPageCount () {
        return this.$store.state.network.providersListPageCount
      },
      flyersListCount () {
        return this.$store.state.network.flyersListCount
      },
      providersListCount () {
        return this.$store.state.network.providersListCount
      },
    },
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
      openAdditionalDetails (account) {
        this.$modal.show('FlyerDetailsPopup', {additionalInfo: account})
      },
      moment,
      tabActivate (event) {
        this.tabs.addActiveForClick(event.currentTarget)
        if (event.currentTarget.getAttribute('data-id') === 'aircraft-providers') {
          this.createShow = true
          this.tabSelected = 'aircraft-providers'
        } else {
          this.createShow = false
          this.tabSelected = 'flyers'
        }
      },
      togglePayment (event) {
        const item = event.currentTarget.closest('.js-acc-item').querySelector('.js-acc-content-network')
        event.currentTarget.closest('.js-acc-item').classList.toggle('mutation')
        document.querySelectorAll('.js-acc-content-network').forEach((content) => {
          if (item !== content) {
            content.querySelector('.networkDashboard__table-row-content-inner').style.opacity = 0
            content.closest('.js-acc-item').classList.remove('mutation')
            this.$root.animations.slideUp(content)
          }
        })

        this.$root.animations.slideToggle(item)
        const innerContent = item.querySelector('.networkDashboard__table-row-content-inner')

        if (window.getComputedStyle(innerContent).opacity === '1') {
          innerContent.style.opacity = 0
        } else {
          item.addEventListener('transitionend', () => {
            if (window.getComputedStyle(innerContent).opacity === '0') {
              innerContent.style.opacity = 1
            }
          }, { once: true })
        }
      },
      async exportToExcel () {
        try {
          const { data } = await reportsAPI.network(this.tabSelected)
          const downloadLink = document.createElement('a');
          const blob = new Blob(['\ufeff', data]);
          const url = window.URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = `network_${this.tabSelected}_report_` + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (e) {
          console.log(e)
        }
      },
      async onFlyersPageClick (data) {
        await this.$store.dispatch('getFlyersList', data);
      },
      async onProvidersPageClick (data) {
        await this.$store.dispatch('getProvidersList', data);
      },
      getDisplayDateTime (dateString) {
      const options = {timeZone: 'America/New_York', hour12: false};
      let date = new Date(dateString);
      date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return dateString === null
        ? ''
        : date.toLocaleString('en-US', options);
    },
    },
    components: {
      SvgIcon,
      SignUpProviderPopup,
      FlyerDetailsPopup,
      Pagination,
    },
  }
</script>